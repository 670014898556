<template>
  <div>
    <!-- 企业管治 -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.Corporate Governance")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Corporate Governance</i
        ></span
      >
    </div>
    <!-- 跳转 -->
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/investors/default">{{ $t("indexnav.Investor Relations") }}</a> >
      {{ $t("indexnav.Corporate Governance") }} -->
      <bread-crumb />
    </section>
    <section>
      <div id="iframeContainer"></div>
      <!-- <div class="loading_box" v-if="showLoading"></div>
      <div class="container gov-link">
        <div v-for="item in dataList" :key="item.id">
          <h2>{{ filterLang(item, "Title") }}</h2>
          <a
            v-if="item.addressType == 'jump_url'"
            :href="filterLang(item, 'JumpUrl')"
            target="_blank"
            >{{ filterLang(item, "Content") }}</a
          >
          <a
            v-if="item.addressType == 'pdf_file'"
            :href="filterLang(item, 'FileUrl')"
            target="_blank"
            >{{ filterLang(item, "Content") }}</a
          >
          <br />
          <br />
          <br />
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
import { QiYeGuanZhi } from "@/api/investors";
import { filterLang } from "@/utils/LangChange";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import { ifarmeMixin } from "../mixin/ifarmeMixin";

export default {
  mixins: [ifarmeMixin],
  data() {
    return {
      dataList: [],
      showLoading: true,
      banner: "",
      izh: "https://jinxinfertility.website.wisdomir.com/sc/ir_gov.php",
      itr: "https://jinxinfertility.website.wisdomir.com/tc/ir_gov.php",
      ien: "https://jinxinfertility.website.wisdomir.com/en/ir_gov.php",
    };
  },
  methods: {
    filterLang,
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.QIYEGUANZHI,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    QiYeGuanZhi().then((res) => {
      this.showLoading = false;
      console.log(res);
      this.dataList = res.result;
    });
  },
  mounted() {
    if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  },
};
</script>

<style scoped lang='less'>
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}
.container {
  margin: auto;
  overflow: hidden;
  padding: 0 20px;
  margin: 3em auto 0 auto;
  h2 {
    font-size: 1rem;
    color: #f00;
    padding-bottom: 30px;
    font-weight: bold;
  }
  a {
    text-decoration: underline;
    color: #666666;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font: 14px/2 "宋体", Arial;
  }
}
@media screen and (max-width: 680px) {
  .ab-item1 {
    font-size: 1.2em !important;
    padding: 0 1.5em 1.5em 1.5em !important;
  }
}
.ab-item1 {
  padding: 0 1.5em 1.5em 1.5em;
}
</style>
